import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DynamicDashboardPeriod, LoadSettingsPayload } from "interfaces";

interface SettingsState {
    accessPermits: string[],
    dynamicDashboardPeriod: DynamicDashboardPeriod | null
}

const initialState: SettingsState = {
    accessPermits: [],
    dynamicDashboardPeriod: null,
}

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        loadSettings: (state, action: PayloadAction<LoadSettingsPayload>) => {
            state.accessPermits = action.payload.accessPermits;
            state.dynamicDashboardPeriod = action.payload.dynamicDashboardPeriod;
        },
        clearSettingsState: (state) => {
            state.accessPermits = [];
            state.dynamicDashboardPeriod = null;
        }
    },
})

export const { loadSettings, clearSettingsState, } = settingsSlice.actions
