import { useIonRouter } from "@ionic/react";
import { ImagotipoDynamicDashboard, ImagotipoGondolaCold, ImagotipoHome, ImagotipoInventory, ImagotipoIvaCards, ImagotipoLogout, ImagotipoOrders, ImagotipoSellersControl, LogoHorizontalPremierTools } from "assets"
import { useAppDispatch, useAppSelector } from "hooks";
import { signOutSession } from "services";
import { PagesNames } from "types";

interface MenuOption {
    icon: () => JSX.Element,
    title: string,
    route: string,
    permission: string,
}

const menuOptions: MenuOption[] = [
    {
        icon: ImagotipoDynamicDashboard,
        title: "Dashboard Dinámico",
        route: "/dashboard",
        permission: "dynamic_dashboard",
    },
    {
        icon: ImagotipoSellersControl,
        title: "Control de Vendedores",
        route: "/sellersControl",
        permission: "sellers_control",
    },
    {
        icon: ImagotipoGondolaCold,
        title: "Góndola y Frío",
        route: "/gondolaCold",
        permission: "gondola_cold",
    },
    {
        icon: ImagotipoInventory,
        title: "Inventario",
        route: "/inventory",
        permission: "inventory",
    },
    {
        icon: ImagotipoOrders,
        title: "Pedidos",
        route: "/orders",
        permission: "orders",
    },
    {
        icon: ImagotipoIvaCards,
        title: "Tarjetas de I.V.A.",
        route: "/ivaCards",
        permission: "iva_cards",
    },
]


export const SidebarMenu = ({ pageName }: { pageName: PagesNames }) => {
    const { accessPermits } = useAppSelector(state => state.settings);

    const ionRouter = useIonRouter();
    const dispatch = useAppDispatch();

    const renderOptions = () => {
        return menuOptions.map((option: MenuOption) => {
            if (accessPermits.includes(option.permission)) {
                return (
                    <div
                        key={option.route}
                        onClick={() => ionRouter.push(option.route, "none", "push")}
                        className="flex items-center gap-4 cursor-pointer"
                    >
                        <div className="flex items-center gap-2">
                            {
                                pageName === option.permission &&
                                <span className="text-secondary font-bold text-3xl">·</span>
                            }

                            <div className={`p-2 flex items-center gap-1 ${pageName !== option.permission && "ml-5"} ${pageName === option.permission && 'bg-light border border-tertiary rounded-md'}`}>

                                {
                                    option.icon()
                                }
                            </div>
                        </div>

                        <p className={`text-md ${pageName === option.permission && "font-bold"} `}>{option.title}</p>
                    </div>
                )
            }
        });
    };

    return (
        <div className="w-full h-screen flex flex-col flex-grow gap-12 px-4 py-6 border-r-2 border-light">
            <div
                onClick={() => ionRouter.push("/menu", "none", "push")}
                className="w-full flex justify-center cursor-pointer"
            >
                <LogoHorizontalPremierTools />
            </div>

            <div className="w-full flex flex-col gap-4">
                <div
                    onClick={() => ionRouter.push("/menu", "none", "push")}
                    className="flex items-center gap-4 cursor-pointer"
                >
                    <div className="flex items-center gap-2">
                        {
                            pageName === "menu" &&
                            <span className="text-secondary font-bold text-3xl">·</span>
                        }

                        <div className={`p-2 flex items-center gap-1 ${pageName !== "menu" && "ml-5"} ${pageName === "menu" && 'bg-light border border-tertiary rounded-md'}`}>
                            <ImagotipoHome />
                        </div>
                    </div>

                    <p className={`text-md ${pageName === "menu" && "font-bold"}`}>Inicio</p>
                </div>

                {renderOptions()}
            </div>

            <div className="w-full mt-auto flex flex-col gap-4">
                <div
                    onClick={() => signOutSession(dispatch, ionRouter)}
                    className="flex items-center gap-4 cursor-pointer pt-2 border-t border-solid border-tertiary"
                >
                    <div className="flex items-center gap-2">
                        <div className={`p-2 flex items-center gap-1 ml-5`}>
                            <ImagotipoLogout />
                        </div>
                    </div>

                    <p className={`text-md`}>Cerrar Sesión</p>
                </div>

                <div className="pt-2 font-bold border-t border-solid border-tertiary">
                    <p className="ml-7 text-mid">V 1.0.0</p>
                </div>
            </div>
        </div>
    )
}