import { FirebaseAuth } from "firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect } from "react"
import { logout } from "store/auth";
import { IonReactRouter } from "@ionic/react-router";
import { LoadingPage } from "components";
import { AuthRouter } from "./AuthRouter";
import { AppRouter } from "./AppRouter";
import { validateAuth } from "services";

export const MainRouter = () => {
    const { authStatus } = useAppSelector(state => state.auth);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(FirebaseAuth, (user) => {
            if (user) {
                validateAuth(dispatch);
            } else {
                dispatch(logout());
            }
        });

        // Limpia el listener cuando el componente se desmonte
        return () => unsubscribe();
    }, []);

    if (authStatus === "checking") {
        return <LoadingPage />
    }

    return (
        <IonReactRouter>
            {
                authStatus !== "authenticated" && <AuthRouter />
            }

            {
                authStatus === "authenticated" && <AppRouter />
            }
        </IonReactRouter>
    )
}