import { IonContent, IonPage, useIonRouter } from "@ionic/react"
import { IconDynamicDashboard, IconGondolaCold, IconInventory, IconIvaCards, IconOrders, IconSellersControl } from "assets"
import { OutlineButton, SidebarMenu } from "components"
import { useAppDispatch, useAppSelector } from "hooks"
import { signOutSession } from "services"

interface MenuOption {
    icon: () => JSX.Element,
    title: string,
    route: string,
    permission: string
}

const menuOptions: MenuOption[] = [
    {
        icon: IconDynamicDashboard,
        title: "Dashboard Dinámico",
        route: "/dashboard",
        permission: "dynamic_dashboard",
    },
    {
        icon: IconSellersControl,
        title: "Control de Vendedores",
        route: "/sellersControl",
        permission: "sellers_control",
    },
    {
        icon: IconGondolaCold,
        title: "Góndola y Frío",
        route: "/gondolaCold",
        permission: "gondola_cold",
    },
    {
        icon: IconInventory,
        title: "Inventario",
        route: "/inventory",
        permission: "inventory",
    },
    {
        icon: IconOrders,
        title: "Pedidos",
        route: "/orders",
        permission: "orders",
    },
    {
        icon: IconIvaCards,
        title: "Tarjetas de I.V.A.",
        route: "/ivaCards",
        permission: "iva_cards",
    },
]

export const HomePage = () => {
    const { accessPermits } = useAppSelector(state => state.settings);

    const dispatch = useAppDispatch();
    const ionRouter = useIonRouter();

    const renderOptions = () => {
        return menuOptions.map((option: MenuOption) => {
            if (accessPermits.includes(option.permission)) {
                return (
                    <div key={option.route} className="w-full flex justify-center">
                        <div
                            onClick={() => ionRouter.push(option.route, "none", "push")}
                            className="w-full h-full xl:w-3/5"
                        >
                            <div className="bg-white h-full px-6 py-4 flex flex-col flex-grow items-center gap-4 border border-light rounded-xl text-center cursor-pointer">
                                {
                                    option.icon()
                                }
                                <p className="text-primary mt-auto text-2xl">{option.title}</p>
                            </div>
                        </div>
                    </div>
                )
            }
        });
    };

    return (
        <IonPage>
            <IonContent>
                <div className="w-full h-full grid grid-cols-12">
                    <div className="h-full col-span-2 border-r-2 border-solid border-light">
                        <SidebarMenu pageName="menu" />
                    </div>

                    <div className="w-full flex flex-col items-center col-span-10">
                        <div className="w-full p-4 border-b-2 border-solid border-light flex justify-between items-center">
                            <p className="font-bold text-xl">Dashboard</p>
                        </div>

                        <div className="w-full h-full bg-light flex justify-center items-center">
                            <div className="container grid grid-cols-3 p-4 gap-8">
                                {
                                    renderOptions()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}