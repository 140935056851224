import { IonRouterOutlet } from "@ionic/react"
import { SignInPage } from "app/auth"
import { Redirect, Route } from "react-router-dom"

export const AuthRouter = () => {
    return (
        <IonRouterOutlet>
            <Route path="/auth/signin" component={SignInPage} exact />
            <Redirect exact from="/" to="/auth/signin" />
            <Redirect to="/auth/signin" />
        </IonRouterOutlet>
    )
}
