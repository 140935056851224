import { IonContent, IonPage } from "@ionic/react";
import axios from "axios";
import * as echarts from "echarts";
import { FirebaseAuth } from "firebase-config";
import { useAppSelector } from "hooks";
import { useEffect, useRef, useState } from "react"

interface Channel {
    name: string,
    compliance: number,
}

export const DashboardDynamicPage = () => {
    const { uid } = useAppSelector(state => state.user);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [percentage, setPercentage] = useState<number>(0);
    const [channels, setChannels] = useState<Channel[]>([]);
    const [optionsGauge, setOptionsGauge] = useState<any>();

    const chartRef = useRef(null);

    // Función para obtener el mes y año de la campaña actual
    const getCampaignData = () => {
        axios.post(`${process.env.REACT_APP_SERVICE_URL}/settings/app/info/dashboard`, {
            token: uid
        }).then((res) => {
            if (res.data.data?.mounth && res.data.data?.year) {
                getGoalsData(res.data.data.mounth, res.data.data.year);
            } else {
                throw new Error("Ha ocurrido un error al recuperar los datos de la campaña actual");
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    // Función para obtener el porcentaje de cumplimiento de cada canal
    const getGoalsData = (month: number, year: number) => {
        const body = {
            data: {
                year,
                month,
                calculateDetails: true,
                order: true
            }
        }

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `UserToken ${uid}`,
        }

        axios.post(`${process.env.REACT_APP_SERVICE_URL}/sapuser/get/goals/vendor`, body, { headers })
            .then((res) => {
                if (res.data.data?.details && res.data.data?.sold) {
                    // Obtenemos el porcentaje de cumplimiento global de la empresa
                    setPercentage(res.data.data.details.percentage);

                    // Obtenemos el porcentaje de cumplimiento de cada canal
                    let channelsArray: Channel[] = [];

                    res.data.data.sold.forEach((channel: any) => {
                        const newChannel: Channel = {
                            name: channel.vendor,
                            compliance: channel.cumplimiento
                        };

                        channelsArray.push(newChannel);
                    });

                    setChannels(channelsArray);

                    const chart = echarts.init(chartRef.current);

                    chart.setOption({
                        series: [
                            {
                                data: [
                                    {
                                        value: res.data.data.details.percentage,
                                        name: '%'
                                    }
                                ],
                                type: 'gauge',
                                startAngle: 180,
                                endAngle: 0,
                                min: 0,
                                max: 100,
                                splitNumber: 3,
                                pointer: {
                                    icon: 'circle',
                                    length: '12%',
                                    width: 50,
                                    offsetCenter: [0, '-90%'],
                                    itemStyle: {
                                        color: '#FFFFFF',
                                        borderColor: 'auto', // This doesn't work :(
                                        borderWidth: 5,
                                        shadowColor: 'rgba(10, 31, 68, 0.5)',
                                        shadowBlur: 2,
                                        shadowOffsetY: 1,
                                    },
                                },
                                axisLine: {
                                    show: true,
                                    roundCap: true,
                                    lineStyle: {
                                        width: 9,
                                        color: [
                                            [0.5, '#e76262'],
                                            [0.54],
                                            [0.66, '#f9cf4a'],
                                            [0.7],
                                            [0.83, '#eca336'],
                                            [0.87],
                                            [1, '#3ece80'],
                                        ],
                                    },
                                },
                                axisTick: {
                                    length: 2,
                                    lineStyle: {
                                        color: '#8a94a6',
                                        width: 2,
                                    },
                                },
                                splitLine: {
                                    show: false,
                                },
                                axisLabel: {
                                    show: false,
                                },
                                title: {
                                    show: false,
                                },
                                detail: {
                                    rich: {
                                        header: {
                                            fontSize: 36,
                                            fontWeight: 700,
                                            fontFamily: 'Open Sans',
                                            color: '#0a1f44',
                                        },
                                        subHeader: {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Open Sans',
                                            color: '#8a94a6',
                                        },
                                    },
                                    offsetCenter: [0, '-20%'],
                                    valueAnimation: true,
                                },
                            },
                        ]
                    });
                } else {
                    throw new Error("Ha ocurrido un error al recuperar los datos de la campaña actual");
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getCampaignData();
    }, []);

    const logoutHandler = async () => {
        await FirebaseAuth.signOut();
    }

    return (
        <IonPage>
            <IonContent>
                <div className="w-full flex">
                    {
                        isLoading &&
                        <div>
                            Cargando...
                        </div>
                    }

                    <div ref={chartRef} className="w-[20%] h-[250px] flex justify-center" />

                    <button onClick={logoutHandler}>Cerrar sesión</button>
                </div>
            </IonContent>
        </IonPage>
    )
}