import { IonContent, IonIcon, IonPage, IonSpinner, useIonRouter } from "@ionic/react"
import { Pagination } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks"
import { LegacyRef, MutableRefObject, useEffect, useRef, useState } from "react"
import { getClientsService, signOutSession } from "services";
import { playCircle } from 'ionicons/icons';
import { OutlineButton, SearchField, SidebarMenu, SolidButton } from "components";
import { formatDateToLocaleDateString, formatDateToLocaleFullDateString, getAllDocuments } from "utils";
import { orderBy } from "firebase/firestore";
import { alertCircle, checkmarkCircle } from 'ionicons/icons';
import * as XLSX from 'xlsx';

export const IvaCardsPage = () => {
    const { uid, vendorCode } = useAppSelector(state => state.user);

    const [clientsList, setClientsList] = useState<any[]>([]);
    const [filteredClients, setFilteredClients] = useState<any[]>([]);
    const [visibleClients, setVisibleClients] = useState<any[]>([]);
    const [searcherValue, setSearcherValue] = useState<string>("");
    const [paginationCount, setPaginationCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [recordsPerView] = useState<number>(12);
    const [loadingReport, setLoadingReport] = useState<boolean>(true);
    const [filteringReport, setFilteringReport] = useState<boolean>(false);
    const [errorGettingClients, setErrorGettingClients] = useState<boolean>(false);
    const [firstTime, setFirstTime] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const scrollableElementRef = useRef<any>(null);
    const ionRouter = useIonRouter();
    const dispatch = useAppDispatch();

    const getIvaCards = async () => {
        try {
            setLoadingReport(true);

            if (uid) {
                if (vendorCode) {
                    const documents = await getAllDocuments("vat", orderBy("createdAt", "desc"));

                    if (documents && documents.data) {
                        const ivaCardsList: any[] = [];

                        documents.data.forEach((item: any) => {
                            ivaCardsList.push(item.data());
                        });

                        setClientsList(ivaCardsList);
                        setFilteredClients(ivaCardsList);

                        const visibleClientsList = ivaCardsList.slice(recordsPerView * (currentPage - 1), recordsPerView * currentPage);
                        setVisibleClients(visibleClientsList);
                        setErrorGettingClients(false);

                        setPaginationCount(Math.ceil(ivaCardsList.length / recordsPerView));
                    } else {
                        throw new Error("Ocurrió un error al intentar recuperar el listado de clientes. Intenta recargar nuevamente la página y si el error persiste comunícate con el equipo de soporte técnico");
                    }
                } else {

                }
            }
        } catch (error: any) {
            setErrorMessage(error.message);
            setErrorGettingClients(true);
        } finally {
            setLoadingReport(false);
        }
    }

    useEffect(() => {
        getIvaCards();
    }, []);

    const changePaginationHandler = async (event: React.ChangeEvent<unknown>, value: number) => {
        setFilteringReport(true);

        const visibleReportAux = filteredClients.slice(recordsPerView * (value - 1), recordsPerView * value);
        setVisibleClients(visibleReportAux);
        setCurrentPage(value);

        if (scrollableElementRef.current) {
            scrollableElementRef.current.scrollTo({ top: 0, behavior: "smooth" });
        }


        setFilteringReport(false);
    }

    const changeSearcherValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearcherValue(event.target.value);
    }

    useEffect(() => {
        if (!firstTime) {
            if (searcherValue.length > 0) {
                setFilteringReport(true);
            }

            const timerId = setTimeout(async () => {
                if (searcherValue && searcherValue.length > 0) {
                    let inputValueAux = searcherValue.toLowerCase();

                    const filteredClientsArray = clientsList.filter((client: any) => (
                        client.cardCode?.toLowerCase().includes(inputValueAux) ||
                        client.cardFName?.toLowerCase().includes(inputValueAux) ||
                        client.cardName?.toLowerCase().includes(inputValueAux) ||
                        client.numberDocument?.toLowerCase().includes(inputValueAux) ||
                        client.nrc?.toLowerCase().includes(inputValueAux)
                    ));

                    setFilteredClients(filteredClientsArray);

                    const visibleReportAux = filteredClientsArray.slice(0, recordsPerView);
                    setVisibleClients(visibleReportAux);
                    setPaginationCount(Math.ceil(filteredClientsArray.length / recordsPerView));
                } else {
                    setFilteredClients(clientsList);

                    const visibleReportAux = clientsList.slice(0, recordsPerView);
                    setVisibleClients(visibleReportAux);
                    setPaginationCount(Math.ceil(clientsList.length / recordsPerView));
                }

                setCurrentPage(1);
                setFilteringReport(false);
            }, 1000);

            // Limpiar el temporizador anterior en cada cambio de inputValue
            return () => {
                clearTimeout(timerId);
            }
        } else {
            setFirstTime(false);
        }
    }, [searcherValue]);

    // Función para descargar el archivo
    const downloadExcel = () => {
        const cardCodes = clientsList.map((item) => item.cardCode);

        // Convierte el arreglo en un formato que la biblioteca xlsx pueda entender
        const worksheetData: string[][] = cardCodes.map(item => [item]);
        const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(worksheetData);

        // Crea un nuevo libro de trabajo
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'CÓDIGOS DE CLIENTES');

        // Convierte el workbook a un archivo binario
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Crea un Blob a partir del buffer binario
        const blob: Blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `Tarjetas_IVA_${formatDateToLocaleFullDateString(new Date())}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const downloadClientsInfo = (clientsInfo: any[]) => {
        // Definir los encabezados de las columnas
        const headers = ['CÓDIGO DE CLIENTE', 'RAZÓN SOCIAL', 'NOMBRE DEL CONTRIBUYENTE', 'NIT', 'NRC', 'CORREO',
            'TELÉFONO', 'GIRO O ACTIVIDAD ECONÓMICA', 'DIRECCIÓN', 'DEPARTAMENTO', 'MUNICIPIO'];

        // Convertir el arreglo en un formato que la biblioteca xlsx pueda entender
        const worksheetData: string[][] = [
            headers, // Primera fila con los encabezados
            ...clientsInfo.map(item => [
                item.cardCode,
                item.cardFName,
                item.cardName,
                item.numberDocument,
                item.nrc,
                item.email,
                item.phoneNumber,
                item.economicActivity,
                item.address,
                item.departament,
                item.municipality
            ])
        ];

        const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(worksheetData);

        // Crear un nuevo libro de trabajo
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'TARJETAS DE IVA');

        // Convertir el workbook a un archivo binario
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Crear un Blob a partir del buffer binario
        const blob: Blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `Tarjetas_IVA_${formatDateToLocaleFullDateString(new Date())}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <IonPage>
            <IonContent>
                <div className="w-full h-screen grid grid-cols-12">
                    <div className="col-span-2 border-r-2 border-solid border-light">
                        <SidebarMenu pageName="iva_cards" />
                    </div>

                    {
                        loadingReport &&
                        <div className="w-full h-screen flex flex-col justify-center items-center col-span-10">
                            <IonSpinner name="crescent" color="primary" className="w-[50px] h-[50px]" />
                        </div>
                    }

                    {
                        !loadingReport && errorGettingClients &&
                        <div className="w-full h-screen flex flex-col justify-center items-center p-4 col-span-10">
                            <div className="w-full flex flex-col items-center gap-4">
                                <p className="font-semibold text-primary text-center">{errorMessage}</p>

                                <div className="w-full">
                                    <SolidButton
                                        text="Recargar"
                                        backgroundColor="primary"
                                        disabled={false}
                                        onClickHandler={getIvaCards}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    {
                        !loadingReport && !errorGettingClients &&
                        <div className="w-full h-screen flex flex-col items-center col-span-10">
                            <div className="w-full p-4 border-b-2 border-solid border-tertiary flex justify-between items-center">
                                <p className="font-bold">Tarjetas de I.V.A.</p>

                                <div className="flex items-center gap-4">
                                    <SolidButton
                                        text="Descargar códigos de clientes"
                                        backgroundColor="primary"
                                        disabled={false}
                                        onClickHandler={downloadExcel}
                                    />

                                    <SolidButton
                                        text="Descargar información completa"
                                        backgroundColor="primary"
                                        disabled={false}
                                        onClickHandler={() => downloadClientsInfo(clientsList)}
                                    />

                                    <SolidButton
                                        text="Descargar página actual"
                                        backgroundColor="primary"
                                        disabled={false}
                                        onClickHandler={() => downloadClientsInfo(visibleClients)}
                                    />
                                </div>
                            </div>

                            <div className="w-full p-4 border-b-2 border-solid border-tertiary">
                                <div className="w-2/5">
                                    <SearchField onChangeHandler={changeSearcherValueHandler} />
                                </div>
                            </div>

                            {
                                filteringReport &&
                                <div className="w-full h-screen flex justify-center pt-6">
                                    <IonSpinner name="crescent" color="primary" className="w-[50px] h-[50px]" />
                                </div>
                            }

                            {
                                !filteringReport && !(visibleClients.length > 0) &&
                                <div className="w-full h-screen flex justify-center pt-6">
                                    <p className="font-bold text-primary">NO SE ENCONTRARON RESULTADOS</p>
                                </div>
                            }

                            {
                                !filteringReport && visibleClients.length > 0 &&
                                <div ref={scrollableElementRef} id="main-section" className="container h-screen overflow-y-scroll flex flex-col flex-grow">
                                    <div className="w-[90%] gap-4 px-8 py-4 grid grid-cols-3">
                                        {
                                            visibleClients.map((client: any) => (
                                                <div
                                                    key={client.$key}
                                                    className="flex flex-col flex-grow p-4 rounded-md account-information-card text-small font-medium"
                                                >
                                                    <div className="w-full flex justify-between">
                                                        <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.381 11.5605C17.0198 10.0797 16.2875 8.38215 14.7453 7.7688C13.2031 7.15546 11.4351 7.85863 10.7963 9.33938C10.1575 10.8201 10.8898 12.5177 12.432 13.1311C13.9742 13.7444 15.7422 13.0412 16.381 11.5605Z" fill="#434343" />
                                                            <path d="M25.5266 18.5338V10.149C26.0834 9.80969 26.4529 9.21335 26.4529 8.53475C26.4529 7.47573 25.5587 6.6172 24.4558 6.6172C24.1077 6.6172 23.7838 6.70202 23.5 6.85111L15.5758 2.65099C15.5116 1.64852 14.6469 0.854248 13.5867 0.854248C12.5266 0.854248 11.6833 1.62795 11.6003 2.61244L3.29328 6.7997C3.03627 6.68403 2.74982 6.6172 2.44731 6.6172C1.34435 6.6172 0.450195 7.47573 0.450195 8.53475C0.450195 9.08997 0.696489 9.58606 1.08735 9.93565V18.7497C0.696489 19.0993 0.450195 19.598 0.450195 20.1506C0.450195 21.2096 1.34435 22.0682 2.44731 22.0682C2.74982 22.0682 3.0336 22.0013 3.2906 21.8857L11.6003 26.0961C11.6833 27.0805 12.54 27.8568 13.5867 27.8568C14.6335 27.8568 15.5142 27.06 15.5758 26.0575L23.5 21.8368C23.7838 21.9859 24.1077 22.0682 24.4531 22.0682C25.556 22.0682 26.4502 21.2096 26.4502 20.1506C26.4502 19.472 26.0808 18.8757 25.5239 18.5364L25.5266 18.5338ZM22.4667 20.0324L14.5425 24.253C14.2587 24.104 13.9348 24.0191 13.5894 24.0191C13.2441 24.0191 12.8934 24.1117 12.6016 24.271L4.44443 20.1403C4.44443 19.3512 3.94381 18.6752 3.2317 18.3847V10.2929C3.94113 10.0025 4.43907 9.32645 4.44443 8.53989L12.5989 4.42974C12.8907 4.59168 13.2307 4.68422 13.5921 4.68422C13.9535 4.68422 14.264 4.59939 14.5478 4.45031L22.4693 8.65042C22.5095 9.28018 22.8656 9.82512 23.3876 10.1464V18.5312C22.8629 18.8525 22.5068 19.4 22.4693 20.0298L22.4667 20.0324Z" fill="#434343" />
                                                            <path d="M14.3953 15.3053H12.6177C10.9579 15.3053 9.61133 16.5982 9.61133 18.1919V20.148H17.4044V18.1919C17.4044 16.5982 16.0578 15.3053 14.398 15.3053H14.3953Z" fill="#434343" />
                                                        </svg>

                                                        {
                                                            client.hasOwnProperty("status") && client.status === true &&
                                                            <div className="flex items-center gap-1">
                                                                <IonIcon icon={checkmarkCircle} className="text-success text-[22px] mb-[2px]" />
                                                                <p className="text-success text-large font-bold">REVISADO</p>
                                                            </div>
                                                        }

                                                        {
                                                            (!client.hasOwnProperty("status") || client.status === false) &&
                                                            <div className="flex items-center gap-1">
                                                                <IonIcon icon={alertCircle} className="text-warning text-[22px] mb-[2px]" />
                                                                <p className="text-warning text-large font-bold">PENDIENTE</p>
                                                            </div>
                                                        }


                                                        {/* <div className="flex items-center gap-1">
                                                            <p className="text-success text-large font-bold">REVISADO</p>
                                                            <IonIcon icon={checkmarkCircle} className="text-success text-[22px] mb-[2px]" />
                                                        </div> */}
                                                    </div>

                                                    <p className="mt-3">{client.cardCode}</p>

                                                    <p className="mt-1 text-mid font-bold">{client.cardName}</p>
                                                    <p className="mb-2">{client.cardFName}</p>

                                                    <div className="w-full mt-auto h-[1px] border border-solid border-light"></div>

                                                    <div className="w-full mt-3 flex justify-between items-center">
                                                        <p>Ver datos de I.V.A.</p>

                                                        <IonIcon
                                                            onClick={() => ionRouter.push(`/ivaCards/${client.$key}`, "none", "push")}
                                                            icon={playCircle}
                                                            className="text-primary text-[25px] cursor-pointer"
                                                        />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>

                                    <div className="w-full flex justify-center mt-auto py-4">
                                        <Pagination
                                            size="large"
                                            color="primary"
                                            count={paginationCount}
                                            defaultPage={1}
                                            page={currentPage}
                                            siblingCount={1}
                                            boundaryCount={2}
                                            onChange={changePaginationHandler}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </IonContent>
        </IonPage>
    )
}