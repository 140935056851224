import { IonRouterOutlet } from "@ionic/react"
import { DashboardDynamicPage } from "app/dashboard-dynamic"
import { GondolaColdPage } from "app/gondola-cold"
import { InventoryPage } from "app/inventory"
import { IvaCardDetailPage, IvaCardsPage } from "app/iva-cards"
import { HomePage } from "app/menu"
import { OrdersPage } from "app/orders"
import { SellersControlPage } from "app/seller-control"
import { useAppSelector } from "hooks"
import { Redirect, Route } from "react-router-dom"

const routes = [
    { path: "/dashboard", component: DashboardDynamicPage, permission: "dynamic_dashboard" },
    { path: "/sellersControl", component: SellersControlPage, permission: "sellers_control" },
    { path: "/gondolaCold", component: GondolaColdPage, permission: "gondola_cold" },
    { path: "/inventory", component: InventoryPage, permission: "inventory" },
    { path: "/orders", component: OrdersPage, permission: "orders" },
    { path: "/ivaCards", component: IvaCardsPage, permission: "iva_cards" },
    { path: "/ivaCards/:cardKey", component: IvaCardDetailPage, permission: "iva_cards" },
];

export const AppRouter = () => {
    const { accessPermits } = useAppSelector(state => state.settings);

    const renderRoutes = () => {
        return routes.map(route => {
            if (accessPermits.includes(route.permission)) {
                return <Route key={route.path} path={route.path} component={route.component} exact />;
            }
            return <Redirect key={`${route.path}_menu`} to="/menu" />
        });
    };

    return (
        <IonRouterOutlet>
            <Route path="/home" component={HomePage} exact />

            {
                renderRoutes()
            }

            <Redirect exact from="/" to="/home" />
            <Redirect to="/home" />
        </IonRouterOutlet>
    )
}
